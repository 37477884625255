<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body>
        <b-row>
          <b-col lg="4" md="3" sm="4">
            <b-form-group
                label-cols="12"
                :label="$t('campaigns.menu.spamWord')"
                label-for="input-sm"
            >
              <b-form-input v-model="form.word"/>
            </b-form-group>
          </b-col>
          <b-col style="margin-top: 35px;">
            <button
                type="button"
                class="btn waves-effect waves-light btn-gradient"
                @click="findSpamWord"
            >
              {{ $t('common.Search') }}
            </button>
            <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
              {{ $t('common.resetSearch') }}
            </button>
          </b-col>
        </b-row>
        <hr/>
        <b-row align-v="center" class="pb-3">
          <b-col>
            <div class="d-flex align-items-center">
              <span class="mr-3">{{ $t('common.perPage') }}</span>
              <div>
                <b-form-select
                    v-model="form.$limit"
                    :options="pageOptions"
                    size="sm"
                    @change="findSpamWord"
                />
              </div>
              <div class="ml-3">
                <total-list-component :total="totalAll"/>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable }}">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(createdAt)="data">
            {{ changeDateFormat(data.item.createdAt) }}
          </template>
        </b-table>
      </b-card>
    </b-col>
    <div class="ml-auto">
      <b-pagination
          v-model="form.$skip"
          :total-rows="totalRows"
          :per-page="form.$limit"
          align="fill"
          class="my-0"
          size="sm"
          @input="findSpamWord"
      />
    </div>

  </b-row>
</template>

<script>

import functionsCommon from "@/common/functions";
import _ from "lodash";
import securityApi from "@/repository/securityApi";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "smsTemplate",
  components: {TotalListComponent, EmptyTable},
  data: () => ({
    totalAll: 0,
    form: {
      search: '',
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    fields: [
      {
        key: "word",
        label: "spam.list.word",
      },
      {
        key: "createdAt",
        label: "spam.list.createdAt",
      },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
  }),
  mounted() {
    this.findSpamWord()
  },
  methods: {
    findSpamWord() {
      this.isBusy = !this.isBusy
      securityApi.getSpamWordAll(_.cloneDeep(this.form))
          .then((response) => {
            this.totalAll = response.total
            this.totalRows = response.total
            this.items = response.data
          }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    resetSearch() {
      this.form = {
        word: '',
        $skip: 1,
        $limit: 10,
      }
      this.findSpamWord()
    }
  }
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
