<template>
  <div>
    <topic-component :topic="$t('campaigns.topic')"/>
    <b-row>
      <b-col sm="12" md="12" lg="3" class="mb-2">
        <b-card class="mb-1 border send-box-bg" border-variant="primary" v-for="menu in menuList" :key="menu.key"
                @click="activeMenu(menu.key)" :class="menuSelected === menu.key ?'active' :''"
                v-if="checkPermission(menu.permissions, 'tab')">
          <b-row>
            <b-col cols="4">
              <div>
                <i :class="`${menu.imgAction} display-4 `"></i>
              </div>
            </b-col>
            <b-col cols="8">
              <h4 class="card-title">{{ $t(menu.text) }}</h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12" md="12" lg="9">
        <b-card v-if="menuSelected!=='' ">
          <quick-send :free_trial_message="free_trial_message" v-if="menuSelected==='quickSend'" @output="getCredit"/>
          <campaign-builder v-if="menuSelected==='campaignBuilder'" @output="getCredit"/>
          <send-using-file v-if="menuSelected==='sendUsingFile'"/>
          <sms-template v-if="menuSelected==='smsTemplate'"/>
          <spam-word v-if="menuSelected==='spamWord'"/>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import QuickSend from "@/views/sms/quickSend";
import CampaignBuilder from "@/views/sms/campaignBuilder";
import SendUsingFile from "@/views/sms/sendUsingFile";
import SmsTemplate from "@/views/sms/template/index";
import TopicComponent from "@/components/common/topicComponent";
import SpamWord from "@/views/sms/spamWord/index";
import functions from "@/common/functions";
import authApi from "@/repository/authApi";
import {store} from "@/store";
import subscriptionApi from "@/repository/subscriptionApi";
import alert from "@/common/alert";

export default {
  name: "index",
  components: {TopicComponent, SmsTemplate, SendUsingFile, CampaignBuilder, QuickSend, SpamWord},
  data() {
    return {
      free_trial_message: '',
      menuList: [
        {
          key: 'quickSend',
          text: 'campaigns.menu.quickSend',
          imgAction: 'fas fa-paper-plane',
          permissions: 'quick-send'
        },
        {
          key: 'sendUsingFile',
          text: 'campaigns.menu.sendUsingFile',
          imgAction: 'fas fa-file-alt',
          permissions: 'send-using-file'
        },
        {
          key: 'campaignBuilder',
          text: 'campaigns.menu.campaignBuilder',
          imgAction: 'fas fa-envelope',
          permissions: 'campaign-builder'
        },
        {
          key: 'smsTemplate',
          text: 'campaigns.menu.smsTemplate',
          imgAction: 'far fa-list-alt',
          permissions: 'sms-templates'
        },
        {key: 'spamWord', text: 'campaigns.menu.spamWord', imgAction: 'far fa-thumbs-down', permissions: 'spams'},
      ],
      menuSelected: 'quickSend'
    }
  },
  mounted() {
    let active = sessionStorage.getItem('_a_menu_sms')
    if (active) {
      this.menuSelected = active || 'quickSend'
    } else {
      this.filterPermission()
    }
  },
  methods: {
    activeMenu(menu) {
      sessionStorage.setItem('_a_menu_sms', menu)
      this.menuSelected = menu
    },
    checkPermission(permission, type) {
      return functions.userCheckPermission(permission, type)
    },

    getCredit() {
      authApi.getCustomerProfile(true).then(response => {
        if (response?.codeSYS === '001') {
          store.dispatch('profile/setProfileImg', response?.image)
          store.dispatch('profile/setName', response?.first_name + " " + response?.last_name)
        }
      })
    },
    filterPermission() {
      this.menuSelected = _.filter(this.menuList, item => {
        let per = this.checkPermission(item.permissions, 'tab');
        return item.permissions.includes(per?.module)

      })[0].key
    }
  }

}
</script>

