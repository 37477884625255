<template>
  <b-row>
    <b-col cols="12">
      <b-card no-body class="mb-4">
        <b-row>
          <b-col lg="4" md="3" sm="4">
            <b-form-group
                label-cols="12"
                :label="$t('campaigns.campaignFrom.template_name')"
                label-for="input-sm"
            >
              <b-form-input v-model="form.name"/>
            </b-form-group>
          </b-col>
          <b-col lg="4" md="3" sm="4">
            <select-filter-component
                :label-select="$t('contacts.groupList.status')"
                :options="statusList"
                v-model="form.status"
            />
          </b-col>
          <b-col style="margin-top: 35px">
            <button
                type="button"
                class="btn waves-effect waves-light btn-gradient"
                @click="findSmsTemplate"
            >
              {{ $t("common.Search") }}
            </button>
            <button
                type="button"
                class="btn waves-effect waves-light btn-outline ml-2"
                @click="resetSearch"
            >
              {{ $t("common.resetSearch") }}
            </button>
          </b-col>
        </b-row>
        <hr/>
        <b-row align-v="center" class="pb-3">
          <b-col>
            <div class="d-flex align-items-center">
              <span class="mr-3">{{$t('common.perPage')}}</span>
              <div>
                <b-form-select
                    v-model="form.$limit"
                    :options="pageOptions"
                    size="sm"
                    @change="findSmsTemplate"
                />
              </div>
              <div class="ml-3">
                <total-list-component :total="totalAll"/>
              </div>
            </div>
          </b-col>
          <b-col align="end">
            <b-button variant="primary" v-b-modal.modal-create-sms-template
                      :disabled="!checkPermission('create', 'action', 'sms-templates')"
            ><i class="fas fa-plus-circle"></i>
              {{ $t("button.addNew") }}
            </b-button>
          </b-col>
        </b-row>
        <b-table
            responsive
            class="mb-0"
            :items="items"
            :fields="fields"
            :busy="isBusy"
            :empty-text="$t('empty')"
            show-empty
        >
          <template #head()="{ label, field: { key, sortable } }">
            {{ $t(label) }}
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong> {{ $t('loading') }}</strong>
            </div>
          </template>
          <template #empty="scope">
            <empty-table/>
          </template>
          <template #cell(status)="data">
            <switch-component
                v-model="data.item.status"
                @change="activeStatus(data.item._id, data.item.status)"
            />
          </template>
          <template #cell(action)="data">
            <i
                @click="viewDetail(data.item._id)"
                v-b-modal:modal-update-sms-template
                style="color: #7367f0"
                class="action-button icon-note mr-2"
            ></i>
            <i
                style="color: #df3f1e"
                class="action-button icon-trash"
                @click="deleteSMSTemplate(data.item._id)"
            ></i>
          </template>
        </b-table>
      </b-card>
    </b-col>
    <div class="ml-auto">
      <b-pagination
          v-model="form.$skip"
          :total-rows="totalRows"
          :per-page="form.$limit"
          align="fill"
          class="my-0"
          size="sm"
          @input="findSmsTemplate"
      />
    </div>
    <create-sms-template @output="findSmsTemplate"/>
    <update-sms-template @output="findSmsTemplate" :tag-detail="tagDetail"/>
  </b-row>
</template>

<script>
import CreateSmsTemplate from "@/views/sms/template/create";
import UpdateSmsTemplate from "@/views/sms/template/view";
import smsTemplatesApi from "@/repository/smsTemplatesApi";
import alert from "@/common/alert";
import SwitchComponent from "@/components/common/switchComponent";
import masterData from "@/common/masterData.json";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "smsTemplate",
  components: {
    TotalListComponent,
    EmptyTable,
    UpdateSmsTemplate,
    CreateSmsTemplate,
    SwitchComponent,
    SelectFilterComponent,
  },
  data: () => ({
    totalAll: 0,
    form: {
      status: null,
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    tagDetail: {},
    fields: [
      {
        key: "name",
        label: "sms.list.name",
      },
      {
        key: "message",
        label: "sms.list.message",
      },
      {
        key: "status",
        label: "sms.list.status",
      },
      {
        key: "action",
        label: "sms.list.actions",
      },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
    statusList: masterData.activeList,
    isUpdateVisible: false
  }),
  mounted() {
    this.findSmsTemplate();
    this.findTag();
  },
  methods: {
    findTag() {
      smsTemplatesApi
          .getTag()
          .then((response) => {
            this.$store.dispatch("template/setTagList", response.data);
          })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    findSmsTemplate() {
      this.isBusy = !this.isBusy
      smsTemplatesApi
          .findSMSTemplateAll(_.cloneDeep(this.form))
          .then((response) => {
            this.totalAll = response.total;
            this.totalRows = response.total;
            this.items = response.data;
          }).finally(() => {
        this.isBusy = !this.isBusy
      })
    },
    viewDetail(id) {
      smsTemplatesApi
          .getById(id)
          .then((response) => {
            this.tagDetail = response;
            this.$bvModal.show("modal-update-sms-template");
          })
          .catch((err) => {
            alert.findFailed(this, err.response.data.message);
          });
    },
    deleteSMSTemplate(id) {
      this.$swal
          .fire({
            icon: "warning",
            title: this.$t("common.delete.topic"),
            text: this.$t("common.delete.detail"),
            showCancelButton: true,
            confirmButtonColor: "#EA5455",
            cancelButtonColor: "#7367F0",
            cancelButtonText: this.$t("button.cancel"),
            confirmButtonText: this.$t("button.yes"),
          })
          .then((result) => {
            if (result.isConfirmed) {
              smsTemplatesApi
                  .delete(id)
                  .then((response) => {
                    if (response?.codeSYS === "001") {
                      this.findSmsTemplate();
                      alert.deleteSuccess(this);
                    } else {
                      alert.deleteFailed(this, response.message);
                    }
                  })
                  .catch((err) => {
                    alert.deleteFailed(this, err.response.data.message);
                  });
            }
          });
    },
    activeStatus(id, status) {
      const data = {status: status};
      smsTemplatesApi
          .activeStatus(id, data)
          .then((response) => {
            if (response?.codeSYS === "001") {
              alert.updateSuccess(this);
              window.location.reload();
            } else {
              alert.updateFailed(this, response.message);
            }
          })
          .catch((err) => {
            alert.updateFailed(this, err.response.data.message);
          });
    },
    resetSearch() {
      this.form = {
        name: "",
        status: null,
        $skip: 1,
        $limit: 10,
      };
      this.findSmsTemplate();
    },
  },
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
