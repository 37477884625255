var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-create-sms-template","size":"lg","hide-footer":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('h5',[_vm._v(_vm._s(_vm.$t("sms.create")))]),_c('i',{staticClass:"fas fa-times hover-icon",on:{"click":function($event){return _vm.clearData()}}})]}}])},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","lg":"7","md":"7"}},[_c('div',[_c('input-component',{attrs:{"label-input":_vm.$t('campaigns.campaignFrom.template_name'),"state":_vm.validateState('name'),"require-label":_vm.validateState('name') !== null &&
            !_vm.$v.form.name.required
              ? _vm.$t('common.requiredField')
              : '',"required":""},model:{value:(_vm.$v.form.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.name, "$model", $$v)},expression:"$v.form.name.$model"}}),_c('select-component',{attrs:{"label-select":_vm.$t('campaigns.campaignFrom.availableTag'),"options":_vm.tagList},on:{"input":_vm.addTagTextarea},model:{value:(_vm.selectTag),callback:function ($$v) {_vm.selectTag=$$v},expression:"selectTag"}}),_c('input-message-component',{ref:"myTextarea",attrs:{"id":"text_message","label-input":_vm.$t('campaigns.campaignFrom.message'),"rows":"5","state":_vm.validateState('messageTag'),"require-label":_vm.validateState('messageTag') !== null &&
            !_vm.$v.form.messageTag.required
              ? _vm.$t('common.requiredField')
              : '',"required":""},on:{"output":_vm.messageMax},model:{value:(_vm.$v.form.messageTag.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.messageTag, "$model", $$v)},expression:"$v.form.messageTag.$model"}}),_c('select-component',{attrs:{"label-select":_vm.$t('campaigns.campaignFrom.status'),"options":_vm.statusList,"state":_vm.validateState('status'),"required":"","require-label":_vm.validateState('status') !== null && !_vm.$v.form.status.required
              ? _vm.$t('common.requiredField')
              : ''},model:{value:(_vm.$v.form.status.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.status, "$model", $$v)},expression:"$v.form.status.$model"}}),_c('b-row',{staticClass:"mr-1 mt-3",attrs:{"align-h":"end"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.$v.form.$invalid || _vm.is_loading},on:{"click":_vm.create}},[_c('i',{staticClass:"fas fa-save"}),_vm._v(" "+_vm._s(_vm.$t("button.save"))+" "),(_vm.is_loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1)]),_c('b-col',{attrs:{"sm":"12","lg":"5","md":"5"}},[_c('preview',{attrs:{"message":_vm.form.messageTag,"per-message":_vm.perMessage}})],1)],1),_c('b-row',{staticClass:"mt-4 mb-4"},[_c('b-col',[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('messageText.note'))+" ")]),_c('div',{staticStyle:{"padding-left":"10px"},attrs:{"align":"left"}},[_vm._v(" "+_vm._s(_vm.$t('messageText.note1'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('messageText.note2'))+" "),_c('br')])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }