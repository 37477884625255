<template>
  <b-modal
      id="modal-create-sms-template"
      size="lg"
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5>{{ $t("sms.create") }}</h5>
      <i class="fas fa-times hover-icon" @click="clearData()"></i>
    </template>
    <b-row>
      <b-col sm="12" lg="7" md="7">
        <div>
          <input-component
              :label-input="$t('campaigns.campaignFrom.template_name')"
              v-model="$v.form.name.$model"
              :state="validateState('name')"
              :require-label="
              validateState('name') !== null &&
              !$v.form.name.required
                ? $t('common.requiredField')
                : ''
            "
              required
          ></input-component>
          <!-- <select-component
            :change="addTagTextarea"
            v-model="selectTag"
            :label-select="$t('campaigns.campaignFrom.availableTag')"
            :options="tagList"
          >
          </select-component> -->
          <select-component
              v-model="selectTag"
              :label-select="$t('campaigns.campaignFrom.availableTag')"
              :options="tagList"
              @input="addTagTextarea"
          >
          </select-component>

          <input-message-component
              id="text_message"
              @output="messageMax"
              :label-input="$t('campaigns.campaignFrom.message')"
              rows="5"
              v-model="$v.form.messageTag.$model"
              ref="myTextarea"
              :state="validateState('messageTag')"
              :require-label="
              validateState('messageTag') !== null &&
              !$v.form.messageTag.required
                ? $t('common.requiredField')
                : ''
            "
              required
          />
          <select-component
              :label-select="$t('campaigns.campaignFrom.status')"
              v-model="$v.form.status.$model"
              :options="statusList"
              :state="validateState('status')"
              required
              :require-label="
              validateState('status') !== null && !$v.form.status.required
                ? $t('common.requiredField')
                : ''
            "
          />
          <b-row align-h="end" class="mr-1 mt-3">
            <b-button
                variant="primary"
                @click="create"
                :disabled="$v.form.$invalid || is_loading"
            ><i class="fas fa-save"></i> {{ $t("button.save") }}
              <b-spinner small v-if="is_loading"></b-spinner>
            </b-button>
          </b-row>
        </div>
      </b-col>
      <b-col sm="12" lg="5" md="5">
        <preview
            :message="form.messageTag"
            :per-message="perMessage"
        />
      </b-col>
    </b-row>
    <b-row class="mt-4 mb-4">
      <b-col>
        <div class="d-flex">
          <div>
            {{$t('messageText.note')}}
          </div>
          <div align="left" style="padding-left: 10px;">
            {{$t('messageText.note1')}} <br>
            {{$t('messageText.note2')}} <br>
            <!--                  <span class="text-danger">{{$t('messageText.note3')}}</span> <br>-->
            <!--                  <span class="text-danger">{{ $t('campaigns.campaignFrom.remark') }}</span>-->
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import InputComponent from "@/components/common/inputComponent";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import Preview from "@/views/sms/preview";
import SelectComponent from "@/components/common/selectComponent";
import InputMessageComponent from "@/components/common/inputMessageComponent";
import smsTemplatesApi from "@/repository/smsTemplatesApi";
import alert from "@/common/alert";
import {required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import masterData from "@/common/masterData.json";

export default {
  name: "createSmsTemplate",
  components: {
    InputMessageComponent,
    SelectComponent,
    Preview,
    InputTextAreaComponent,
    InputComponent,
  },
  mixins: [validationMixin],
  data() {
    return {
      perMessage: 160,
      form: {
        name: '',
        availableTag: '',
        status: 1,
        messageTag: '',
      },
      statusList: masterData.activeList,
      selectTag: null,
      is_loading: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      status: {
        required,
      },
      messageTag: {
        required,
      },
    },
  },
  computed: {
    tagList() {
      return this.$store.getters["template/getTagList"];
    },
  },
  mounted() {
  },
  methods: {
    addTagTextarea(e) {
      const oldMessage = _.cloneDeep(this.form.messageTag)
      const tagNew = _.cloneDeep(e)

      this.form.messageTag = oldMessage + "{" + tagNew + "}"
      this.selectTag = null

    },
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    messageMax(e) {
      this.perMessage = e;
    },
    create() {
      const data = {
        name: this.form.name,
        message: this.form.messageTag,
        status: this.form.status,
      }
      this.is_loading = true
      smsTemplatesApi.create(data).then(response => {
        if (response?.codeSYS === '001') {
          this.$emit('output', 'success');
          this.$bvModal.hide('modal-create-sms-template')
          this.clearData()
          alert.addNewSuccess(this)
        } else {
          alert.addNewFailed(this, response.message)
        }
      }).catch(err => {
        if (err?.response?.status !== 401) {
          alert.addNewFailed(this, err.response.data.message)
        }
      }).finally(() => {
        this.is_loading = false
      })
    },
    clearData() {
      const data = {
        name: '',
        availableTag: '',
        status: 1,
        messageTag: '',
      };
      this.form = data;
      this.$v.form.$reset()
      this.$bvModal.hide("modal-create-sms-template");
    },
  },
};
</script>

<style scoped>
</style>